@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

:root {
  primary-white: #fff;
}

body {
  font-family: "Roboto Condensed", sans-serif !important;
}

.logo {
  width: 8rem;
}

nav a.nav-link {
  /* font-weight: 500; */
  letter-spacing: 0.0375rem;
  /* cursor: pointer; */
}
.nav-link:hover {
  /* font-weight: 500; */
  /* letter-spacing: 0.0375rem;
  cursor: pointer; */
  color: #ffffff !important;
}
nav ul li {
  text-transform: uppercase;
}

.bg-dark {
  background-color: #212529 !important;
}
.navbar-nav > li > a {
  /* color: var(primary-white) !important; */
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #fff !important;
  /* border-top: 0.1875rem solid; */
}
.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

@media (max-width: 768px) {
  .navbar-brand {
    width: 3rem;
  }
}
.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-top: 0.1875rem solid;
  color: #186fed !important;
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid;
  color: #186fed !important;
}
nav {
  z-index: 2;
  background-color: #1c1c1c;
}

nav img {
  margin-top: 2rem;
}
/* ************Header********* */

.header-wraper {
  position: relative;
  background: url("./logo/wall-and-laptop-background.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: #186fed;
  text-transform: uppercase;
}

.btn-main-offer {
  padding: 0 !important;
}
@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/* *****Typed Text***** */

.typed-text {
  font-size: 2 rem;
  color: #fff;
}
/* *****Header Main Offer Button ***** */
.btn-main-offer {
  border: 1px solid red;
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #fff;
  margin: 2rem;
}
.btn-main-offer:hover {
  text-decoration: none;
  background-color: red;
  transition: 0.5s ease-in-out;
}

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vh;
  z-index: 1;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/* *****About Me****** */

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: 0.1rem;
  border: 1px solid #186fed;
}

.about-heading {
  color: #186fed;
  text-transform: uppercase;
}
.logo-navbar {
  width:8rem;
}

@media (max-width: 468px) {
  .profile-img {
    width: 20rem;
  }
}

/* *****Services***** */

.services {
  text-align: center;
  padding-bottom: 2rem;
  background: #e7e6e6;
}

.services h1 {
  color: #186fed;
  text-transform: uppercase;
}
.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: #186fed;
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid #186fed;
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: #ffffff;
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: #186fed;
  border-bottom: 0.3125rem solid black;
}

.services .box:hover .icon {
  color: black;
}
.services .box:hover .circle {
  background: #ffffff;
}
.services .box:hover p {
  color: #ffffff;
}

/* *********Experience******** */

.experience {
  padding: 0.7rem;
}
.experience-wrapper {
  position: relative;
  padding: 3.125rem;
  overflow: hidden;
}
.experience h1 {
  text-transform: uppercase;
  color: #186fed;
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: #e7e6e6;
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}
.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid black;
  background: #186fed;
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375 rem;
  color: gray;
  direction: ltr;
}
.timeline-content h3 {
  margin-top: 0.3125 rem;
  color: red;
  font-weight: 900;
}

/* **********Media********* */

@media (max-width: 768px) {
  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    direction: ltr;
    width: 100%;
  }
  .experience .container {
    padding-left: 0;
  }
}

/* ******Portfolio***** */

.image-box-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.portfolio-wrapper {
  background: #e7e6e6;
  padding: 3rem 0;
  color: #186fed;
}
.portfolio-image {
  width: 15rem;
  border: 1px solid #186fed;
  padding: 0 0.1rem;
}
.portfolio-image-box {
  margin: 1rem;
  position: relative;
}

/* *******Overflow***** */
.overflow {
  position: absolute;
  opacity: 70%;
  background: #000000;
  width: 15rem;
  height: 8.5rem;
  top: 0;
  border: 1px solid red;
  cursor: pointer;
}
.portfolio-image-box:hover .overflow {
  opacity: 10%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: #186fed;
  font-size: 1.5rem;
}
.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

/* ******POPup box */

.portfolio-image-popupbox {
  width: 30rem;
  padding: 0 0.5 rem;
}

.popupbox-wrapper {
  height: 70% !important;
  margin-top: 5rem;
}
/* ****MEDIA*** */
@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}

@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 80%;
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
  .popupbox-content {
    height: 100%;
  }
}
@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 70%;
  }
  .portfolio-image-popupbox {
    width: 35%;
  }
  .popupbox-content {
    height: 100%;
  }
}
/* ****** testimonials *** */

.testimonials {
  background-image: url();
  background-size: cover;
  background-color: #e7e6e6;
  text-align: center;
  padding: 4rem;
  margin-bottom: 0;
  width: 100%;
}
.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem;
  background: rgba(0, 0, 0, 0.7);
}
.myCarousel {
  color: #ffffff;
  background: transparent;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: 17.87rem;
}
.carousel .slide img {
  width: 10rem !important;
  border-radius: 50%;
}
.testimonials h1 {
  color: #186fed;
  text-transform: uppercase;
  margin-top: -4rem;
}
.myCarousel h3 {
  color: #186fed;
  font-weight: 600;
  text-transform: uppercase;
}
.myCarousel p {
  color: #e7e6e6;
}
.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
}
.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 1rem;
}

/* *******Contacts***** */
.contacts {
  background: #263646;
  padding: 3rem 0;
}
.contacts h1 {
  color: #186fed;
  padding: 1.3rem 0;
  text-transform: uppercase;
}
.contacts p {
  color: #e7e6e6;
  font-size: 1.2rem;
}
/* ***************Inputs******************* */

.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid #adadad;
  border-radius: 0;
  color: #adadad;
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}
.contacts .container {
  padding: 4rem 0;
}
.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: #186fed;
  border-bottom: 0.125rem solid #e7e6e6;
}
.contacts textarea {
  height: 12.2rem !important;
}
.contact-btn {
  margin: 0.5rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  width: 0;
  top: -0.8rem;
  height: 0.125rem;
  background: #186fed;
  display: inline-block;
}
input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}
textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: red;
  text-align: left;
}
.success-message {
  font-weight: 900;
  color: green;
}
/* ********Media******** */
@media (max-width: 768px) {
  .contacts .container {
    padding: 0.2rem;
  }
  .contacts p {
    padding: 0 0.4rem;
  }
  .contacts {
    overflow-x: hidden;
  }
}
/* ************Footer************ */
.footer {
  background: black;
  color: #adadad;
  padding: 3rem;
}
.footer a {
  text-decoration: none;
  color: #adadad;
}

.footer a:hover {
  text-decoration: none;
  color: #186fed;
  cursor: pointer;
}
.footer .d-flex p {
  margin-bottom: 0rem !important;
}
